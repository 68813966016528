import './home.scss';
import { setupLayout } from '../Shared/layout';
import * as $ from 'jquery';
import 'owl.carousel/dist/owl.carousel';

setupLayout();

$(document).ready(function () {
    $(".bannerCarousel").owlCarousel({
        loop: true,
        autoplay: true,
        autoplayTimeout: 10000,
        dots: false,
        responsive: {
            0: {
                items: 1
            },
            769: {
                items: 1,
                nav: true
            }
        }
    });

    $("#categoryCarousel").owlCarousel({
        dots: false,
        responsiveClass: true,
        responsive: {
            0: {
                items: 3,
                margin: 10
            },
            421: {
                items: 3,
                margin: 16
            },
            769: {
                items: 3
            },
            1025: {
                items: 4
            },
            1365: {
                items: 5
            },
            1600: {
                items: 6
            }
        }
    });

    $("#brandingCarousel").owlCarousel({
        dots: false,
        responsiveClass: true,
        responsive: {
            0: {
                items: 2,
                nav: false,
                margin: 10
            },
            421: {
                items: 2,
                nav: false,
                margin: 10
            },
            769: {
                items: 3,
                nav: false,
                margin: 16
            },
            1025: {
                items: 4,
                nav: true,
                margin: 16
            },
            1365: {
                items: 5,
                nav: true,
                margin: 26
            }
        }
    });

    $(".itemGroupPCNav").first().addClass('active');
    $(".itemGroupMobileNav").first().addClass('active');
    $(".itemCode").each(function (index, elem) {
        if ($(elem).data('itemGroupUrl') !== "air-spring") {
            $(elem).hide();
        };
    });

    $(".itemGroupPCNav, .itemGroupMobileNav").click(function(){
        $(".itemGroupPCNav, .itemGroupMobileNav").removeClass('active');
        var value = $(this).data('itemGroupUrl');
        $(".itemGroupPCNav, .itemGroupMobileNav").each(function (index, elem) {
            if ($(elem).data('itemGroupUrl') == value) {
                $(elem).addClass('active');
            };
        });
        $(".itemCode").each(function (index, elem) {
            $(elem).hide();
            if ($(elem).data('itemGroupUrl') == value) {
                $(elem).show();
            };
        });
    });

});
