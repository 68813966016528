import { setEvent, trySetAdsConversion } from './google-analytics';


declare global {
    interface Window {
        zE: any
    }
}

export function setupLiveChat(zopimLiveChatId: string) {
    let script = document.createElement('script') as HTMLScriptElement;
    script.type = 'text/javascript';
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zopimLiveChatId}`;
    script.onload = () => {
        window.zE('webWidget:on', 'chat:start', () => {
            setEvent('liveChat');
            trySetAdsConversion('liveChat');
        })
    }
    document.getElementsByTagName('body')[0].appendChild(script);
}
