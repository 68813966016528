import { ajaxForm } from "../../../../src/module/form";
import { setEvent, setAdsConversion } from "../../../../src/module/google-analytics";

export function setupContactForm(elementId : string){
    ajaxForm({
        elementId: elementId,
        beforeSend: () => { 
            document.querySelector<HTMLElement>(`#${elementId} .buttonSubmit`).style.display = 'none';
            document.querySelector<HTMLElement>(`#${elementId} .buttonLoading`).style.display = 'block';
        },
        error: (errorText) => {
            document.querySelector<HTMLElement>(`#${elementId} .buttonSubmit`).style.display = 'none';
            document.querySelector<HTMLElement>(`#${elementId} .buttonLoading`).style.display = 'none';
            document.querySelector<HTMLElement>(`#${elementId} .buttonError`).style.display = 'block';
            console.log(errorText);
        },
        processing: () => {
            //upload percentage
        },
        success: () => {
            document.querySelector<HTMLElement>(`#${elementId} .buttonSubmit`).style.display = 'none';
            document.querySelector<HTMLElement>(`#${elementId} .buttonLoading`).style.display = 'none';
            document.querySelector<HTMLElement>(`#${elementId} .buttonError`).style.display = 'none';
            document.querySelector<HTMLElement>(`#${elementId} .buttonSuccess`).style.display = 'block';
            (document.getElementById(elementId) as HTMLFormElement).reset();
            setEvent('contactForm');
            setAdsConversion('contactForm');
        },
        finally: () => {
            //finish all job
        }
    });
}





