import { setupGA } from '../../src/module/google-analytics';
import { setupLiveChat } from '../../src/module/zopim-live-chat';
import { setupExtension } from '../../src/module/extensions';
import { appConfig } from '../../src/appConfig';
import { scrollToTop } from '../../src/module/dom';
import { setupContactForm } from './Component/ContactForm/contact-form';

export function setupLayout() {
    setupExtension();

    setupGA(appConfig.googleAnalyticsId, {
        Id: appConfig.googleAdsId,
        convertor: {
            'hpLink': 'M-PpCK7h0n4QrPTKgwM',
            'whatsApp': 'Ouf6CPWL0H8QrPTKgwM',
            'mapLink': 'M-PpCK7h0n4QrPTKgwM',
            'liveChat': '9E3SCL7j3X8QrPTKgwM',
            'emailLink': 'scsECIzdwYYBEKz0yoMD',
            'contactForm': 'bbTICPPfp4ABEKz0yoMD'
        }
    });
    setupLiveChat(appConfig.zopimLiveChatId);
    setupContactForm('footerForm');

    document.querySelectorAll(".openButton").forEach(each => {
        each.addEventListener("click", function () {
            document.querySelectorAll(".searchForm").forEach(action => {
                action.classList.toggle("active");
            });
        });
    });

    document.querySelector(".menuButton").addEventListener("click", function () {
        document.querySelector(".mobileNavigation").classList.add("active");
        document.querySelector(".backgroundCover").classList.add("active");
    });

    document.querySelector(".menuCloseButton").addEventListener("click", function () {
        document.querySelector(".mobileNavigation").classList.remove("active");
        document.querySelector(".backgroundCover").classList.remove("active");
    });

    document.querySelector(".backgroundCover").addEventListener("click", function () {
        document.querySelector(".mobileNavigation").classList.remove("active");
        document.querySelector(".backgroundCover").classList.remove("active");
    });

    window.onscroll = function () {
        if (window.pageYOffset > 0) {
            document.querySelector("#returnBox").classList.add("active");
        } else {
            document.querySelector("#returnBox").classList.remove("active");
        }
    }

    document.querySelector("#returnBox").addEventListener("click", function () {
        scrollToTop();
    });
}